.page {
  flex: 1 0 auto;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  min-height: 1px;
}

.SupportPage {
  height: 100%;
  margin: 3% 12%;
}

@media (max-width: 499px) {
  .SupportPage {
    margin: 3%;
  }
}

.card {
  height: fit-content;
}

.Footer {
  position: static;
  bottom: 0;
  display: block;
  width: 100%;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#page {
  width: 1200px;
}
.InsideContent {
  display: inline-block;
}

#divTable {
  width: 800px;
}

#divMessage {
  width: 350px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Overwrites some styling from SDX to show the entire Notification Content (especially on mobile) */
.notification__body {
  white-space: pre-wrap !important;
}
